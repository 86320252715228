/* @import url('https://fonts.googleapis.com/css?family=Raleway&display=swap'); */
* {
    box-sizing: border-box;
}

@font-face {
    font-family: 'Raleway';
    src: url('./assets/fonts/Raleway-Regular.ttf');
}

html,
body {
    height: 100%;
    width: 100%;
}

body {
    /* margin: 0; */
    line-height: 1.5;
    font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* overflow: hidden; */
    /* height: 100vh; */
    background-color: #fafafa;
    position: absolute;
    /* height: 100%;
    width: 100%; */
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.swal2-container {
    z-index: 999999;
}
/* @font-face {
    font-family: Copperplate-Gothic;
    src: url('./assets/./fonts/CopperplateGothicBoldRegular.ttf');
  } */
