* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
}
.main__vertical-bar {
    float: left;
}

a {
    text-decoration: none;
    color: whitesmoke;
}

a:hover {
    text-decoration: none;
    color: whitesmoke;
    opacity: 0.8;
}

p {
    margin: 0 !important;
}

button:disabled {
    cursor: not-allowed;
}

.App {
    height: 100%;
    overflow: hidden;
}

.content {
    height: 100%;
    overflow: hidden;
    /* padding-bottom: 25px; */
}

/* @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
        .content {
            height: 100%;
            overflow: hidden;
            padding-bottom: 0px;

        }
    }
} */


.swal2-container { z-index: 999999; }




.mapboxgl-map {
    border-radius: 10px;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
    /* border-bottom-right-radius: 5px;
  border-top-right-radius: 5px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
