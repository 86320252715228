.city-search,
.ward-search {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navbar-items {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    margin-left: 70px;
}

.navbar__selection {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: auto 10px;
}

.navbar-navigation {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.navbar-label {
    letter-spacing: 0.2rem;
    text-align: center;
    /* margin-top: 5px; */
    background-color: black;
    font-size: smaller;
}

.navbar-navigation__button {
    display: inline-block;
    text-align: center;
    font-size: 1.2rem;
    color: white;
    font-weight: bold;
    padding: 0.5rem;
}

.navbar-navigation__button:hover {
    border-bottom: 1px solid white;
}

.navbar-input-box {
    display: flex;
}

.MuiInput-underline:before {
    border-bottom: none !important;
}

.MuiInput-underline:after {
    border-bottom: 1px solid whitesmoke !important;
}

.user_profile {
    position: relative;
    width: auto;
    height: auto;
    margin: 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

span.username {
    font-size: smaller;
    right: 10px;
    margin: 0 2px;
    text-align: center;
}

.user_logo {
    width: 4vw !important;
    /* height: 32px; */
    cursor: pointer !important;
}

.user_logo:hover {
    fill: lightgray;
}

.MuiPopover-paper {
    box-sizing: border-box;
    width: 12% !important;
    height: max-content !important;
    max-height: 20% !important;
    padding: 0 !important;
}

.MuiPopover-paper::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 5px;
}

.MuiPopover-paper::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
    border-radius: 5px;
}

.MuiPopover-paper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    border-radius: 5px;
}

.MuiMenuItem-root {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

.navbar__remove {
    margin-left: 5px;
}

.navbar__remove:hover {
    cursor: pointer;
    font-weight: bolder;
}

a.active {
    color: #20314d;
}

.logo-saksham {
    background-image: url(../../../assets/img/Vector.svg);
    min-width: 70px;
    background-repeat: no-repeat;
    width: 10%;
    height: 100%;
    background-size: contain;
    cursor: pointer;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* @media only screen and (orientation:landscape) and (max-width: 1120px) {
    .navbar-input-box {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin: auto 10px;
    
    }
  } */